import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { makeSelectSegmentListByEventId } from '@/models/event';
import { AttendeeChannelEvent } from '@/types/messaging';
import { usePusherChannelContext } from '@/services/messaging/pusher/pusher-channel-context';
import { SessionBroadcastStatus } from '@/components/PublishedStreamDisplay/SessionSelectBar/types';
import { convertInAnotherTimeZone } from '@/utils/helpers';
import api from '@/api';
import { useChannelQuery } from '@/api/channels';

const useSegmentTracks = ({
  eventDetails,
  isEmbedded,
  stageAttendeeChannel,
  stageData,
  isBroadcasting
}) => {
  const [channelState] = usePusherChannelContext();
  const location = useLocation();
  const { eventAttendeeChannel } = channelState as any;
  const dispatch = useDispatch();
  const { eventId = '', stageId = '' } = useParams<{
    eventId: string;
    stageId: string;
  }>();

  let segmentIdInStudioByUrlParam: string | null = null;
  if (isEmbedded) {
    let selectedSegmentIdHashValue = location.hash
      ?.split(',')
      .find(item => item.includes('selectedSegmentId='));
    selectedSegmentIdHashValue = selectedSegmentIdHashValue?.replace("#", '');
    const [type, value] = selectedSegmentIdHashValue?.split('=') || [];
    if (type === 'selectedSegmentId') {
      segmentIdInStudioByUrlParam = value || '';
    }
  }

  const [liveStageIds, setLiveStageIds] = useState<string[] | undefined>([]);
  const segments = useSelector(makeSelectSegmentListByEventId(eventId));
  const { data: initialLiveStageIds } = useChannelQuery.getLiveStageList(eventId);

  const [currentSegment, setCurrentSegment] = useState<any>({});
  const [segmentStatus, setSegmentStatus] = useState('');
  const [selectedSegmentInDryRun, setSelectedSegmentInDryRun] = useState(null);

  useEffect(() => {
    setLiveStageIds(initialLiveStageIds);
  }, [initialLiveStageIds]);

  const liveStagesStateListener = data => {
    setLiveStageIds(data);
  };

  useEffect(() => {
    if (
      !!stageData?.isDryRun &&
      !!isBroadcasting &&
      stageData?.studioId
    ) {
      api.stage
        .getSelectedSegmentInStudio(stageData?.studioId, stageData?.stageId)
        .then(({ data }) => {
          setSelectedSegmentInDryRun(data?.selectedSegmentId);
        });
    }
  }, []);

  const startDryRunListener = data => {
    api.stage
      .getSelectedSegmentInStudio(data, stageData?.stageId)
      .then(({ data }) => {
        setSelectedSegmentInDryRun(data?.selectedSegmentId);
      });
  };

  const stopDryRunListener = () => {
    setSelectedSegmentInDryRun(null);
  };

  useEffect(() => {
    if (eventAttendeeChannel) {
      eventAttendeeChannel.bind(
        AttendeeChannelEvent.LIVE_STAGES_STATE,
        liveStagesStateListener,
      );
    }
    return () => {
      if (eventAttendeeChannel) {
        eventAttendeeChannel.unbind(
          AttendeeChannelEvent.LIVE_STAGES_STATE,
          liveStagesStateListener,
        );
      }
    };
  }, [eventAttendeeChannel]);

  useEffect(() => {
    if (stageAttendeeChannel) {
      stageAttendeeChannel.bind(
        AttendeeChannelEvent.START_DRY_RUN,
        startDryRunListener,
      );
      stageAttendeeChannel.bind(
        AttendeeChannelEvent.STOP_DRY_RUN,
        stopDryRunListener,
      );
    }
    return () => {
      if (stageAttendeeChannel) {
        stageAttendeeChannel.unbind(
          AttendeeChannelEvent.START_DRY_RUN,
          stopDryRunListener,
        );
        stageAttendeeChannel.unbind(
          AttendeeChannelEvent.STOP_DRY_RUN,
          stopDryRunListener,
        );
      }
    };
  }, [stageAttendeeChannel]);

  const tracks = useMemo(
    () =>
      segments
        .filter((segment: { stageId: string }) =>
          liveStageIds?.includes(segment.stageId),
        )
        .filter(
          (seg: { broadcastStatus: SessionBroadcastStatus }) =>
            seg.broadcastStatus === SessionBroadcastStatus.STARTED,
        ),
    [liveStageIds, segments],
  );

  useEffect(() => {
    dispatch({
      type: 'event/getEventSegmentList',
      payload: {
        eventId,
      },
    });
  }, [dispatch, eventId, liveStageIds]);

  const currentTrack = useMemo(
    () => tracks.find(track => track.stageId === stageId),
    [tracks, stageId],
  );
  const otherRunningTracks = useMemo(
    () => tracks.filter(track => track.stageId !== stageId),
    [tracks, stageId],
  );

  const setCurrentSegmentToShow = sessionToShow => {
    setCurrentSegment(sessionToShow);
  };

  const calculateSessionToShow = (currentStageSegments, currentTime) => {
    let currentSession;

    // current day upcoming segment
    currentSession = currentStageSegments.find(
      segment =>
        convertInAnotherTimeZone(
          segment.startDateTime,
          eventDetails?.tz,
        ).getDate() === currentTime.getDate() &&
        convertInAnotherTimeZone(segment.startDateTime, eventDetails?.tz) >
          currentTime,
    );

    if (currentSession) {
      setSegmentStatus('Upcoming');
    } else {
      // latest completed segment (finding last element as segments are sorted in increasing order in start time)
      currentSession = currentStageSegments.findLast(
        segment =>
          convertInAnotherTimeZone(segment.startDateTime, eventDetails?.tz) <
          currentTime,
      );

      if (currentSession) {
        setSegmentStatus('Previous session');
      } else {
        // next day first session
        currentSession = currentStageSegments.find(
          segment =>
            convertInAnotherTimeZone(segment.startDateTime, eventDetails?.tz) >
            currentTime,
        );

        setSegmentStatus('Upcoming');
      }
    }

    return currentSession;
  };

  // on day change - recalculating current session
  const setSessionOnDayChange = (currentStageSegments, currentTime) => {
    const reCalculateTime =
      new Date(
        convertInAnotherTimeZone(new Date(), eventDetails?.tz).setHours(
          24,
          0,
          0,
          0,
        ),
      ).getTime() - currentTime.getTime();
    var dayChangeTimeout = setTimeout(() => {
      const sessionToShow = calculateSessionToShow(
        currentStageSegments,
        currentTime,
      );
      setCurrentSegmentToShow(sessionToShow);
    }, reCalculateTime);

    return dayChangeTimeout;
  };

  // This useEffect is calculating current session to show on stage
  useEffect(() => {
    if (!segments || segments.length == 0 || !eventDetails) {
      return;
    }

    if (segmentIdInStudioByUrlParam === '' || selectedSegmentInDryRun === '') {
      setCurrentSegmentToShow(null);
      return;
    }

    if (segmentIdInStudioByUrlParam && segmentIdInStudioByUrlParam !== 'null') {
      const segmentInStudio = segments.find(
        s => s.segmentId === segmentIdInStudioByUrlParam,
      );
      setCurrentSegmentToShow(segmentInStudio);
      return;
    }

    let sessionToShow;

    if (selectedSegmentInDryRun) {
      sessionToShow = segments.find(
        s => s.segmentId === selectedSegmentInDryRun,
      );
      setCurrentSegmentToShow(sessionToShow);
      setSegmentStatus('Dry run');
      return;
    }

    sessionToShow = currentTrack;
    let sessionEndTimeout;
    var currentStageSegments = segments.filter(
      segment => segment.stageId === stageId,
    );
    const currentTime = convertInAnotherTimeZone(new Date(), eventDetails?.tz);

    if (!sessionToShow) {
      // live segment by time range (If the session has completed before the time, then we will also keep this session live until segment end time.)
      sessionToShow = currentStageSegments.find(
        segment =>
          currentTime >=
            convertInAnotherTimeZone(segment.startDateTime, eventDetails?.tz) &&
          currentTime <
            convertInAnotherTimeZone(segment.endDateTime, eventDetails?.tz),
      );
    }

    if (sessionToShow) {
      setSegmentStatus('Live now');
    }

    // in case of schedule sync off - will recalculate when the segment ends.
    if (sessionToShow && !eventDetails?.enableScheduleSync) {
      const reCalculateTime =
        convertInAnotherTimeZone(
          sessionToShow.endDateTime,
          eventDetails?.tz,
        ).getTime() - currentTime.getTime();
      sessionEndTimeout = setTimeout(() => {
        sessionToShow = calculateSessionToShow(
          currentStageSegments,
          currentTime,
        );
        setCurrentSegmentToShow(sessionToShow);
      }, reCalculateTime);
    }

    // calculating other session to show If there is no live session
    if (!sessionToShow) {
      sessionToShow = calculateSessionToShow(currentStageSegments, currentTime);
    }

    setCurrentSegmentToShow(sessionToShow);

    // on day change - recalculating current session
    const dayChangeTimeout = setSessionOnDayChange(
      currentStageSegments,
      currentTime,
    );

    return () => {
      clearTimeout(dayChangeTimeout);
      if (sessionEndTimeout) {
        clearTimeout(sessionEndTimeout);
      }
    };
  }, [eventDetails, segments, liveStageIds, selectedSegmentInDryRun]);

  const remainingStageSegments = useMemo(
    () =>
      segments.filter(
        data =>
          data.stageId === stageId &&
          data.segmentId !== currentSegment?.segmentId,
      ),
    [segments, currentSegment, stageId],
  );

  return {
    currentSegment,
    segmentStatus,
    otherRunningTracks,
    remainingStageSegments,
  };
};

export default useSegmentTracks;
